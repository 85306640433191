import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"
import Footer from "../components/Footer"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import TranslationTestimonialSlider from 'components/sliders/translation-testimonial-slider'

import "../scss/translationstyle.scss"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const InterpretersPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  const data = useStaticQuery(
    graphql`
      query {
        hero_background: file(relativePath: { eq: "infinity-icon-small1.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hero_img: file(relativePath: { eq: "interpreters-hero-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:461
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        car_hero: file(relativePath: { eq: "car-hero.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:50
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hipaa_icon: file(relativePath: { eq: "hipaa-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        reliable_icon: file(relativePath: { eq: "reliable-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cost_icon: file(relativePath: { eq: "cost-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        middle_img: file(relativePath: { eq: "middle-line.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        interpretation_img: file(relativePath: { eq: "interpretation-on-your-terms-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        language_img: file(relativePath: { eq: "language-barrier.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        commitment_img: file(relativePath: { eq: "translators-our-commitment-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        section7_img: file(relativePath: { eq: "landing-section7-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        btn_img: file(relativePath: { eq: "btn-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  //declare image Data
  const hero_background = getImage(data.hero_background)
  const hero_img = getImage(data.hero_img)
  const car_hero = getImage(data.car_hero)
  const cost_icon = getImage(data.cost_icon)
  const reliable_icon = getImage(data.reliable_icon)
  const hipaa_icon = getImage(data.hipaa_icon)
  const middle_img = getImage(data.middle_img)
  const interpretation_img = getImage(data.interpretation_img)
  const language_img = getImage(data.language_img)
  const commitment_img = getImage(data.commitment_img)
  const section7_img = getImage(data.section7_img)
  const btn_img = getImage(data.btn_img)

  return (
    <>
      <Helmet bodyAttributes={{
        class: 'translators_body'
      }}>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <Layout>
        <Seo
          title="On Demand Interpretation Services"
          description="We provide interpretation services for any and all situations. Use our online dashboard to book all your appointments today."
        />
        <div className="backround-gradient" >
          <section id="interpreters-hero">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 hero-title">
                  <h1 className="text-center">
                    TRANSCEND <br />INTERPRETATION SERVICES
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-content">
                  <GatsbyImage
                    image={hero_img}
                    alt=""
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="hero-img hero-mobile-img"
                  />
                  <p className="title text-white">
                    Clear Communication is Vital to Every Meeting
                  </p>
                  <p className="sub-title text-white">
                    Use Transcend's online dashboard to book interpreters and manage appointments with ease.
                  </p>
                  <button className="get-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal">Get Started</button>
                </div>
                <div className="col-lg-6">
                  <GatsbyImage
                    image={hero_img}
                    alt=""
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="hero-img"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="interpretation-on-your-terms">
            <Container>
              <div className="col-lg-6 center-content">
                <GatsbyImage
                  image={interpretation_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section5-img"
                />
              </div>
              <div className="col-lg-6 center-content">
                <h2>Interpretation <br />On Your Terms</h2>
                <p className="text-white">
                  Schedule interpreters for every need. Transcend offers over-the-phone, on-site, and video conference call interpretation.
                </p>
              </div>
              <div className="col-lg-12 mb-4 header-container">
                <h2 className="text-white text-center">Perfect Understanding for Every Situation</h2>
                <p className="text-white text-center">
                  Find the best interpreter for every appointment type. Using Transcend's dashboard, enter the language and appointment type, and we'll match you with the perfect interpreter for any situation, including:
                </p>
              </div>
              <div className="col-lg-6 left-content">
                <ul>
                  <li className="text-white">Job Interviews</li>
                  <li className="text-white">Interviews with the Press</li>
                  <li className="text-white">Doctor Appointments</li>
                  <li className="text-white">Hospital Visits</li>
                  <li className="text-white">Therapy Appointments</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li className="text-white">Business Meetings</li>
                  <li className="text-white">Legal Consultations/Proceedings</li>
                  <li className="text-white">Insurance Intakes</li>
                  <li className="text-white">Vocational Endeavors</li>
                  <li className="text-white">Job Counseling</li>
                </ul>
              </div>
              <div className="row language-img-container">
                <div className="col-lg-6">
                  <GatsbyImage
                    image={language_img}
                    alt=""
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="section5-img"
                  />
                </div>
                <div className="col-lg-6">
                  <h2>LANGUAGE IS NO <br />LONGER A BARRIER</h2>
                  <p className="text-white">
                    Choose from over 300 languages. Transcend is focused on growing their network of professional interpreters to meet every client's needs.
                  </p>
                </div>
              </div>
            </Container>
          </section>
          <section id="interpretation-overview" className="text-center">
            <Container>
              <div className="col-lg-12">
                <h2 className="text-center gradient-header">A QUICK OVERVIEW OF HOW TRANSCEND'S INTERPRETATION SERVICES WORK</h2>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={hipaa_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center">
                  EFFORTLESS BOOKING
                </p>
                <p className="text-white text-center section2-content">
                  A case manager books an interpreter with Transcend’s dashboard for on-demand or future appointments.
                </p>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={cost_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center">
                  FLUID COORDINATION
                </p>
                <p className="text-white text-center section2-content">
                  The person requiring the interpreter receives a call or is met by the interpreter on site during the scheduled meeting.
                </p>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={reliable_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center ">
                  CLEAR COMMUNICATION
                </p>
                <p className="text-white text-center section2-content">
                  The interpreter stays the duration of the meeting making sure that all communication is clearly understood.
                </p>
              </div>
            </Container>
          </section>
          <section id="our-commitment">
            <Container>
              <div className="col-lg-6">
                <GatsbyImage
                  image={commitment_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section5-img"
                />
              </div>
              <div className="col-lg-6 right-content">
                <h2>OUR COMMITMENT TO YOUR EXPERIENCE</h2>
                <p className="text-white">
                  Transcend has put customer experience as the highest priority since day one. We strive to exceed all expectations to provide the very best service to our clients.
                </p>
              </div>
            </Container>
          </section>
          <section id="testimonials-section">
            <Container>
              <div className="col-lg-12 testimonial-header-container">
                <h2>Easy for Clients. Easy for You </h2>
              </div>
              <TranslationTestimonialSlider />
            </Container>
          </section>
          <section id="get-started">
            <Container>
              <div className="col-lg-7 get-content">
                <GatsbyImage
                  image={section7_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section7-img section7-mobile"
                />
                <h2>GET STARTED</h2>
                <p className="text-white">
                  Ready to book an interpreter? We've got you covered. Hit get started below and our team will get you set up with everything you need.
                </p>
                <button className="gets-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal">
                  GET STARTED
                </button>
              </div>
              <div className="col-lg-5">
                <GatsbyImage
                  image={section7_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section7-img"
                />
              </div>
            </Container>
          </section>
          <Footer />
        </div>
      </Layout>
    </>
  )
}

export default InterpretersPage
